import supabase from "./supabaseClient";

export const insertQuestion = async (item: any) => {
  const data = item;
  return { data };
};

export const selectQuestions = async () => {
  const data = 1;
  return { data };
};

export const selectAllQuestions = async () => {
  const data = 1;
  return { data };
};

export const selectQuestionById = async (id: any) => {
  const data = id;
  return { data };
};

export const selectQuestionsByQuestionnary = async (questionnary: any) => {
  var { data: mcdata, error: mcerror } = await supabase.from("MultipleChoices").select("*, Question!inner(*)").eq("Question.questionnary", questionnary);
  var { data: fbdata, error: fberror } = await supabase.from("FillBlanks").select("*, Question!inner(*)").eq("Question.questionnary", questionnary);
  var { data: qdata, error: qerror } = await supabase.from("Question").select().eq("questionnary", questionnary);
  if (mcerror) { console.error("[selectQuestionsByQuestionnary] Multiple Choices Error: "+mcerror.message); }
  if (fberror) { console.error("[selectQuestionsByQuestionnary] Fill Blanks Error: "+fberror.message); }
  if (qerror) { console.error("[selectQuestionsByQuestionnary] Question Error: "+qerror.message); }

  if (qdata !== null){
    for (var i = 0; i < qdata.length; i ++){
      if (mcdata !== null){
        for (var j = 0; j < mcdata.length; j ++){
          if (mcdata[j].question === qdata[i].id){
            delete mcdata[j]["Question"];
            qdata[i]["MultipleChoices"] = mcdata[j];
          }
        }
      }

      if (fbdata !== null){
        for (var k = 0; k < fbdata.length; k ++){
          if (fbdata[k].question === qdata[i].id){
            delete fbdata[k]["Question"];
            qdata[i]["FillBlanks"] = fbdata[k];
          }
        }
      }
    }
  }

  const data = qdata;
  return { data };
};

export const selectQuestionByItem = async (id: any) => {
  const data = id;
  return { data };
};

export const updateQuestion = async (item: any) => {
  const data = item;
  return { data };
};

export const deleteQuestion = async (id: any) => {
  const data = id;
  return { data };
};